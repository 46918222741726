<template>
  <div>
    <Statistics2
      :menu-diets="menuDiets"
      :is-busy="isBusy3"
      @listDishe="listDishe"
    />
    <b-row class="mb-2">
      <b-col cols="12">
        <div class="d-flex">
          <b-button
            id="btn1"
            class="btn__ active-btn mr-2"
            :disabled="isDisabled"
            @click="isActive('btn1')"
          >
            <span class="align-middle">Recibidos</span>
          </b-button>
          <b-button
            id="btn2"
            class="btn__ mr-2"
            :disabled="isDisabled"
            @click="isActive('btn2')"
          >
            <span class="align-middle">Enviados</span>
          </b-button>
          <b-button
            id="btn3"
            class="btn__ mr-2"
            :disabled="isDisabled"
            @click="isActive('btn3')"
          >
            <span class="align-middle">Entregados</span>
          </b-button>
          <div class="d-flex align-items-center justify-content-end">
            <date-picker
              v-model="current_day"
              :attributes="attrs"
              mode="date"
              :model-config="modelConfig"
              :available-dates="{ start: null, end: new Date }"
              @input="handleLoadData"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <b-form-input
                  :value="inputValue"
                  :disabled="isDisabled"
                  v-on="inputEvents"
                />
              </template>
            </date-picker>
          </div>
        </div>
      </b-col>
    </b-row>
    <location-table
      :records="records"
      :busy="isBusy"
      :search="search"
      :module="module"
      :title-menu="titleMenu"
      :is-visible="isVisible"
      :is-busy4="isBusy4"
      :record-id="recordId"
      @details="details"
      @sendOrders="sendOrders"
      @printQR="printQR"
    />

    <detailt-order :record="record" />

    <b-modal
      id="modal-assign-transporter"
      centered
      title="Asignar transportador y enviar pedido"
      hide-footer
    >
      <assign-transporter-form
        :order-ids="orderIds"
        :record="null"
        @save="handleLoadData"
      />
    </b-modal>
    <b-modal
      id="modal-report-order"
      centered
      title="Generar reporte de pedidos"
      hide-footer
    >
      <form-report-order />
    </b-modal>
    <b-modal
      id="modal-list-dishes"
      centered
      :title="listDisheByDiet.title"
      hide-footer
      size="sm"
    >
      <list-dishe :list-dishe-by-diet="listDisheByDiet" />
    </b-modal>
    <vue-html2pdf
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :pdf-quality="1"
      :manual-pagination="true"
      :html-to-pdf-options="htmlToPdfOptions"
      pdf-content-width="800px"
      pdf-c
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
    >
      <section slot="pdf-content">
        <div
          v-for="(item, index) in codesQR"
          :key="index"
        >
          <div
            class="pdf-item"
          >
            <qrcode-vue
              :value="item.dataQR"
              :size="200"
              level="M"
              foreground="#34a636"
              class="margin_bottom"
            />
            <h6>
              {{ item.name }}
            </h6>
            <h6>
              {{ item.namePatient }}
            </h6>
            <h6>
              Dieta: {{ item.diet }}
            </h6>
            <h6>
              Consumir antes de 2 horas
            </h6>
          </div>
          <div
            v-if="codesQR.length > 1 && index < codesQR.length - 1"
            class="html2pdf__page-break mb-1"
          />

        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import {
 BButton, BRow, BCol, VBTooltip, BModal, BFormInput,
} from 'bootstrap-vue'
import moment from 'moment'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import VueHtml2pdf from 'vue-html2pdf'
import QrcodeVue from 'qrcode.vue'
import DetailtOrder from '../components/DetailtOrder'
import AssignTransporterForm from '../components/AssignTransporterForm'
import Statistics2 from '../components/Statistics2'
import LocationTable from '../components/LocationTable'
import FormReportOrder from '../components/FormReportOrder'
import ListDishe from '../components/ListDishe'

moment.locale('es')

export default {
	inject: ['orderRepository'],
	components: {
		BButton,
		BRow,
		BCol,
		DetailtOrder,
		AssignTransporterForm,
		Statistics2,
		LocationTable,
		BModal,
		DatePicker,
		BFormInput,
		VueHtml2pdf,
		QrcodeVue,
		FormReportOrder,
		ListDishe,
	},
	directives: {
		'b-tooltip': VBTooltip,
	},
	data() {
		return {
			series: [
				{
					name: 'Hoy',
					data: [],
				},
				{
					name: 'Ayer',
					data: [],
				},
			],
			series2: [],
			series3: [],
			typeDiets: [],
			labels: [],
			records: [],
			record: null,
			search: null,
			orderIds: null,
			isBusy: false,
			isBusy3: true,
			isBusy4: false,
			isDisabled: false,
			isVisible: true,
			typeOrder: 1,
			recordId: 'SIAP',
			dataQR: 'SIAP',
			module: 'kitchenorder',
			titleMenu: 'Ordenes recibidas',
			current_day: (moment(), moment()).format('YYYY-MM-DD'),
			yesterday: (moment().subtract(1, 'days'),
			moment().subtract(1, 'days')).format('YYYY-MM-DD'),
			modelConfig: {
				type: 'string',
				mask: 'YYYY-MM-DD',
			},
			attrs: [
				{
					highlight: 'green',
					dates: new Date(),
				},
			],
			htmlToPdfOptions: {
        margin: [0.3, 0.4, 0.0, 0.4],
        jsPDF: {
          unit: 'in',
          format: 'a7',
          orientation: 'portrait',
        },
        filename: '',
      },
			codesQR: [],
			menuDiets:
			{
				breakfast: {
					items: [],
					quantity: 0,
					snacks: [],
				},
				luches: {
					items: [],
					quantity: 0,
					snacks: [],
				},
				dinners: {
					items: [],
					quantity: 0,
					snacks: [],
				},
			},
			listDisheByDiet: {
				dishes: [],
				title: null,
			},
			customer_id: JSON.parse(localStorage.getItem('user')).customer_id,
		}
	},
	async mounted() {
		const me = this
		await me.loadTypeDiets()
		await me.loadOrdersCurrentDay()
		await me.loadOrdersYesterday()
		await me.handleLoadData()
		me.listenSocket()
	},
	methods: {
		details(item) {
			const me = this
			me.record = item
			// console.log(item)
			this.$bvModal.show('modal-detailt-order')
		},
		sendOrders(ids) {
			const me = this
			me.orderIds = ids
			me.$bvModal.show('modal-assign-transporter')
		},
		async loadOrdersCurrentDay() {
			const me = this
			try {
				const res = await me.orderRepository.getAllByDate(me.current_day)
				const { data } = res
				me.fillMenuDay(data)
				me.fillTypeDiet(data)
			} catch (error) {
				me.series2 = []
				me.series3 = []
				// console.log(error)
			}
		},
		async loadOrdersYesterday() {
			const me = this
			try {
				me.isBusy3 = true
				const res = await me.orderRepository.getAllByDate(me.yesterday)
				const { data } = res
				me.fillMenuYesterday(data)
			} catch (error) {
				// console.log(error)
			} finally {
				me.isBusy3 = false
			}
		},
		async fillMenuDay(data) {
			const me = this
			const luches = []
			const breakfast = []
			const dinners = []
			if (data) {
				data.forEach(el => {
					if (el.menu.type_menu == '1') {
						breakfast.push(el)
					} else if (el.menu.type_menu == '2') {
						luches.push(el)
					} else {
						dinners.push(el)
					}
				})

				me.series2.push(breakfast.length)
				me.series2.push(luches.length)
				me.series2.push(dinners.length)

				me.series[0].data.push(breakfast.length, luches.length, dinners.length)
			}
		},
		async fillMenuYesterday(data) {
			const me = this
			const luchesYesterday = []
			const breakfastYesterday = []
			const dinnersYesterday = []
			if (data) {
				data.forEach(el => {
					if (el.menu.type_menu == '1') {
						breakfastYesterday.push(el)
					} else if (el.menu.type_menu == '2') {
						luchesYesterday.push(el)
					} else {
						dinnersYesterday.push(el)
					}
				})

				me.series[1].data.push(
					breakfastYesterday.length,
					luchesYesterday.length,
					dinnersYesterday.length,
				)
			}
		},
		async fillTypeDiet(data) {
			const me = this
			const diets = []
			const typeDient = []
			if (data) {
				data.forEach(el => {
					me.typeDiets.forEach(diet => {
						if (diet.id == el.diet.id) {
							diets.push(diet)
						}
					})
				})

				diets.forEach(el => {
					const diet = typeDient.find(elem => elem.id == el.id)

					if (diet) {
						diet.types.push(el)
					} else {
						typeDient.push({
							name: el.name,
							id: el.id,
							types: [el],
						})
					}
				})
				typeDient.forEach(el => {
					me.labels.push(el.name)
					me.series3.push(el.types.length)
				})
			}
		},
		async loadTypeDiets() {
			const me = this
			try {
				const res = await me.orderRepository.getAllTypeDiet()
				me.typeDiets = res.data
			} catch (error) {
				// console.log(error)
			}
		},
		async handleLoadData() {
			const me = this
			try {
				me.isBusy = true
				me.records = []
				const res = await me.orderRepository.getAllByDate(me.current_day)
				const { data } = res
				data.forEach(el => {
					if (me.typeOrder == 1 && el.delivery_person == '') {
						me.fillRecordsByStatus(el)
					} else if (me.typeOrder == 2 && el.departure_at !== '') {
						me.fillRecordsByStatus(el)
					} else if (me.typeOrder == 3 && el.arrival_at !== '') {
						me.fillRecordsByStatus(el)
					}
				})
				me.statisticsDietMenu()
			} catch (error) {
				me.records = []
				// console.log(error)
			} finally {
				me.isBusy = false
			}
		},
		printQR() {
      const me = this
			me.codesQR = []
			me.records.forEach(el => {
				me.codesQR.push({
					diet: el.diet.name,
					name: el.room,
					dataQR: el.id + ',' + el.patient_id,
          namePatient: el.patient.first_name + ' ' + el.patient.last_name,
				})
			})
			me.generateReport()
    },
		generateReport() {
      const me = this
			me.isBusy4 = true
      me.htmlToPdfOptions.filename = `SIAP-Reporte-${me.current_day}`
      me.$refs.html2Pdf.generatePdf()
    },
    onProgress(e) {
			if (e == 100) this.isBusy4 = false
    },
		handleSearch(item) {
			this.search = item
		},
		isActive(id) {
			const me = this
			if (id == 'btn1') {
				me.titleMenu = 'Ordenes Recibidas'
				me.isVisible = true
				me.typeOrder = 1
			} else if (id == 'btn2') {
				me.titleMenu = 'Ordenes Enviadas'
				me.isVisible = false
				me.typeOrder = 2
			} else {
				me.titleMenu = 'Ordenes Entregadas'
				me.isVisible = false
				me.typeOrder = 3
			}

			const btns = document.querySelector('.active-btn')
			const btn = document.getElementById(id)

			if (btns) btns.classList.remove('active-btn')
      btn.classList.add('active-btn')

			me.handleLoadData()
		},
		fillRecordsByStatus(el) {
			const me = this
			let type_menu
			let status
			if (el.menu.type_menu == '1') {
				type_menu = 'Desayuno'
			} else if (el.menu.type_menu == '2') {
				type_menu = 'Almuerzo'
			} else {
				type_menu = 'Cena'
			}

			me.records.push({
				...el,
				namePatient: el.patient.first_name + ' ' + el.patient.last_name,
				tipo_diet: el.diet.name,
				room: el.patient.locationpatient.location.name,
				type_menu: type_menu,
				delivery_person: el.delivery_person != '' ? el.delivery_person : 'Sin asignar',
				status: me.typeOrder,
				busy: false,
			})
		},
		statisticsDietMenu() {
			const me = this
			// Tipos de menus
			const luches = []
			const breakfast = []
			const dinners = []
			me.records.forEach(el => {
				if (el.menu.type_menu == '1') {
					breakfast.push(el)
				} else if (el.menu.type_menu == '2') {
					luches.push(el)
				} else {
					dinners.push(el)
				}
			})

			me.menuDiets.breakfast.quantity = breakfast.length
			me.menuDiets.luches.quantity = luches.length
			me.menuDiets.dinners.quantity = dinners.length

			me.dietsByMenu('1', breakfast)
			me.dietsByMenu('2', luches)
			me.dietsByMenu('3', dinners)
		},
		dietsByMenu(type, records) {
			const me = this
			const dietMenus = []
			const snacks = []
			// Tipos de dietas
			records.forEach(el => {
				if (el.snack) snacks.push(el)
				const diet = dietMenus.find(x => x.diet_type_id == el.diet_type_id)

				if (diet) {
					diet.menuDiet.push(el)
				} else {
					dietMenus.push({
						tipo_diet: el.tipo_diet,
						diet_type_id: el.diet_type_id,
						menuDiet: [{
							type_menu: el.type_menu,
							menu: el.menu,
							menu_id: el.menu_id,
							snack: el.snack,
							items: el.items,
						}],
					})
				}
			})

			if (type == '1') {
				me.menuDiets.breakfast.items = dietMenus
				me.menuDiets.breakfast.snacks = snacks
			} else if (type == '2') {
				me.menuDiets.luches.items = dietMenus
				me.menuDiets.luches.snacks = snacks
			} else {
				me.menuDiets.dinners.items = dietMenus
				me.menuDiets.dinners.snacks = snacks
			}
		},
		listDishe(item) {
			const me = this
			me.listDisheByDiet.title = `Listado de platos dieta ${item.tipo_diet}`
			me.listDisheByDiet.dishes = []
			item.menuDiet.forEach(el => {
				el.items.forEach(x => {
					const dishe = me.listDisheByDiet.dishes.find(y => y.name == x.name)
					if (dishe) {
						dishe.items.push(x)
					} else {
						me.listDisheByDiet.dishes.push({
							name: x.name,
							items: [x],
						})
					}
				})
      })
			me.$bvModal.show('modal-list-dishes')
		},
		async refreshData() {
			const me = this
			await me.loadOrdersCurrentDay()
			await me.loadOrdersYesterday()
			await me.handleLoadData()
		},
		listenSocket() {
			const me = this
			window.Echo.channel(`menu-order-customer-${me.customer_id}`)
				.listen(
					'.menu.order.customer.status',
					e => {
						me.refreshData()
					},
				)
				.listen(
					'.menu.order.customer.created',
					e => {
						me.refreshData()
					},
				)
		},
	},
}
</script>

<style lang="scss">
.btn__ {
	background-color: #fff !important;
	border: 1px solid #7367f0 !important;
	padding: 8px !important;
	color: #7367f0 !important;
	border-radius: 0.5rem !important;
	width: 20% !important;
	text-align: center !important;
}

.btn__.active-btn {
	background-color: #7367f0 !important;
	color: #fff !important;
}
.margin_bottom {
	margin-bottom: 1px;
}

.content-pdf-card {

  height: 1500px;
}
</style>
