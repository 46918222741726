<template>
  <b-row>
    <b-col md="12">
      <b-form-group
        label="Nombre del transportador*"
        label-for="delivery_person"
      >
        <b-form-input
          id="delivery_person"
          v-model="formData.delivery_person"
          placeholder="Nombre del transportador"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      class="text-center"
    >
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="success"
        :disabled="isBusy || $v.$invalid"
        @click.prevent="saveData"
      >
        <b-spinner
          v-if="isBusy"
          small
        />
        <v-icon
          v-else
          name="save"
        />
        Enviar
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from 'vuelidate/lib/validators'
import { v4 as uuidv4 } from 'uuid'

export default {
  inject: ['orderRepository'],
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    record: {
      type: Object,
      default: null,
    },
    orderIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      formData: {
        delivery_person: '',
        departure_at: true,
      },
      isBusy: false,
    }
  },
  watch: {
    record(newData) {
      this.formData = { ...this.formData, ...newData }
    },
  },
  validations: {
    formData: {
      delivery_person: {
        required,
      },
    },
  },
  methods: {
    async saveData() {
			const me = this
			try {
				me.isBusy = true
        const uuid = uuidv4()
				await me.orderRepository.save(uuid, { ...me.formData, order_ids: JSON.stringify(me.orderIds) })
        me.$emit('save')
				me.$bvModal.hide('modal-assign-transporter')
				me.$bvToast.toast('Orden(es) enviada(as)', {
					title: 'Éxito',
					toaster: 'b-toaster-bottom-right',
					variant: 'success',
					solid: true,
					appendToast: true,
				})
			} catch (error) {
				// console.log(error)
				me.$bvToast.toast('Error', {
					title: 'Ocurrió un error al enviar orden(es)',
					toaster: 'b-toaster-bottom-right',
					variant: 'danger',
					solid: true,
					appendToast: true,
				})
			} finally {
				me.isBusy = false
			}
		},
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
