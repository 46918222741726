<template>
  <b-card no-body>
    <b-row>
      <b-col
        cols="12"
        class="text-left pl-3 mt-1 d-flex"
      >
        <div class="title_menu mr-2">
          {{ titleMenu }}
        </div>
        <b-button
          variant="danger"
          size="sm"
          class="bg-daren-2 mr-2"
          :disabled="!records.length > 0"
          @click.prevent="generateReport"
        >
          <feather-icon
            icon="DownloadIcon"
          />
          Reporte
        </b-button>
        <b-button
          variant="info"
          size="sm"
          class="bg-daren-2"
          :disabled="!records.length > 0"
          @click.prevent="printQR()"
        >
          <b-spinner
            v-if="isBusy4"
            small
          />
          <feather-icon
            v-else
            icon="DownloadIcon"
          />
          QR
        </b-button>
      </b-col>
      <b-col
        lg="4"
        class="align-self-center pl-3"
      >
        <b-button
          v-if="isVisible"
          variant="success"
          size="sm"
          class="bg-daren-2"
          :disabled="isDisabled"
          @click.prevent="sendOrders"
        >
          <feather-icon
            icon="PlusIcon"
          />
          Enviar
        </b-button>
      </b-col>
      <b-col
        md="8"
        class="my-1 pr-3"
      >
        <b-form-group
          label="Buscar"
          label-cols-sm="3"
          label-align-sm="right"
          label-for="filterInput"
          class="mb-0 w-75 float-right"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder=""
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Limpiar
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-table
          ref="selectableTable"
          responsive
          class="mb-0"
          small
          :busy="busy"
          show-empty
          :filter="filter"
          selectable
          :select-mode="'multi'"
          :items="records"
          :fields="fields"
          selected-variant="success"
          @filtered="onFiltered"
          @row-selected="onRowSelected"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Cargando datos...</strong>
            </div>
          </template>
          <template #empty>
            <h4 class="text-center py-2">
              No hay registros
            </h4>
          </template>
          <template #head(selected)>
            <b-form-checkbox
              v-if="isVisible"
              v-model="statusAll"
              @input="selectAllRows"
            />
            <span
              v-else
              aria-hidden="true"
            >
              &nbsp;
            </span>
          </template>
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <div class="d-flex align-items-center justify-content-center">
                <b-icon-check-2-square
                  class="icon_check"
                  aria-hidden="true"
                />
              </div>
            </template>
            <template v-else>
              <span aria-hidden="true">
                &nbsp;
              </span>
            </template>
          </template>
          <!-- Observation -->
          <template #cell(observations)="data">
            <b-button
              v-if="data.item.observations"
              v-b-tooltip.hover.top="data.item.observations"
              size="sm"
              variant="warning"
              class="btn-icon"
            >
              <feather-icon icon="AlertCircleIcon" />
            </b-button>
            <div v-else>
              Sin observaciones
            </div>
          </template>

          <template #cell(status)="data">
            <b-badge :variant="typeVariant(data.item.status)">
              {{ typeStatus(data.item.status) }}
            </b-badge>
          </template>

          <!-- actions -->
          <template #cell(actions)="data">
            <div class="d-flex align-items-center">
              <b-button
                v-if="hasPermission(`${module}.details`)"
                size="sm"
                variant="success"
                class="btn-icon mr-25"
                @click.prevent="() => $emit('details', data.item)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <!-- <b-button
                v-if="hasPermission(`${module}.print`)"
                :id="data.item.id"
                size="sm"
                variant="info"
                class="btn-icon mr-25"
                @click.prevent="printQR(data.item)"
              >
                <b-spinner
                  v-if="data.item.id == recordId && isBusy4"
                  small
                />
                <feather-icon
                  v-else
                  icon="DownloadIcon"
                />
              </b-button> -->
            </div>
          </template>
        </b-table>
      </b-col>
      <b-col
        md="6"
        class="pl-3"
      >
        <b-form-group
          class="mb-0"
        >
          <label class="d-inline-block text-sm-left mr-50">Páginas</label>
          <b-form-select
            v-model="pagination.perPage"
            size="sm"
            :options="pagination.pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
        class="pr-3 pb-2"
      >
        <b-pagination
          v-model="pagination.currentPage"
          :total-rows="pagination.totalRows"
          :per-page="pagination.perPage"
          align="right"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BButton,
  VBModal,
  VBTooltip,
  BSpinner,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BIconCheck2Square,
  BFormCheckbox,
  BFormSelect,
  BBadge,
} from 'bootstrap-vue'
import PermissionMixin from '@/mixins/PermissionMixin'

export default {
	components: {
		BCard,
		BTable,
		BButton,
    BSpinner,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BPagination,
    BIconCheck2Square,
    BFormCheckbox,
    BFormSelect,
    BBadge,
    /* VueQr */
	},
	directives: {
		'b-modal': VBModal,
		'b-tooltip': VBTooltip,
	},
  mixins: [PermissionMixin],
  props: {
    records: {
      type: Array,
      default: null,
    },
    busy: {
      type: Boolean,
      default: null,
    },
    isBusy4: {
      type: Boolean,
      default: null,
    },
    isVisible: {
      type: Boolean,
      default: null,
    },
    module: {
      type: String,
      default: null,
    },
    titleMenu: {
      type: String,
      default: null,
    },
    search: {
      type: String,
      default: null,
    },
    recordId: {
      type: String,
      default: null,
    },
  },
	data() {
		return {
			fields: [
        'selected',
				{
					key: 'namePatient',
					label: 'Paciente',
					sortable: true,
				},
				{
					key: 'room',
					label: 'Habitaciòn',
					sortable: true,
				},
				{
					key: 'type_menu',
					label: 'Menú',
					sortable: true,
				},
				{
					key: 'tipo_diet',
					label: 'Tipo de dieta',
					sortable: true,
				},
				{
					key: 'date',
					label: 'Fecha',
					sortable: true,
				},
				{
					key: 'hour',
					label: 'Hora',
					sortable: true,
				},
				{
					key: 'observations',
					label: 'Observación',
				},
				{
					key: 'delivery_person',
					label: 'Transportador',
					sortable: true,
				},
        {
          key: 'status',
          label: 'Estado',
        },
				{
					key: 'actions',
					label: 'Acciones',
				},
			],
      id: 'SIAP',
      filter: null,
      isDisabled: true,
      pagination: {
        perPage: 10,
        pageOptions: [10, 20, 50],
        totalRows: 1,
        currentPage: 1,
      },
      filterOn: [],
      sortDesc: false,
      statusAll: false,
      sortDirection: 'asc',
      selected: [],
		}
	},
  watch: {
    search(newData) {
      this.filter = newData
    },
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.pagination.currentPage = 1
    },
    sendOrders() {
      const me = this
      const order_ids = []
      me.selected.forEach(el => {
        order_ids.push({ order_id: el.id })
      })
      me.$emit('sendOrders', order_ids)
    },
    printQR() {
      const me = this
      me.$emit('printQR')
    },
    onRowSelected(items) {
      const me = this
      me.selected = []

      me.records.forEach((el, index) => {
        if (el.status == 2 || el.status == 3) {
          me.$refs.selectableTable.unselectRow(index)
        }
      })

      items.forEach(el => {
        if (el.status == 1) me.selected.push(el)
      })

      if (me.selected.length == me.records.length) {
        me.statusAll = true
      }

      if (me.selected.length == 0) me.statusAll = false

      if (me.selected.length > 0) {
        me.isDisabled = false
      } else {
        me.isDisabled = true
      }
    },
    selectAllRows() {
      const me = this

      if (me.statusAll) {
        me.$refs.selectableTable.selectAllRows()
      } else {
        me.$refs.selectableTable.clearSelected()
      }
    },
    typeVariant(status) {
      let variant = null
      if (status == 1) {
        variant = 'primary'
      } else if (status == 2) {
        variant = 'success'
      } else {
        variant = 'warning'
      }
      return variant
    },
    typeStatus(status) {
      let text = null
      if (status == 1) {
        text = 'Recibida'
      } else if (status == 2) {
        text = 'Enviada'
      } else {
        text = 'Entregada'
      }
      return text
    },
    generateReport() {
      const me = this
      me.$bvModal.show('modal-report-order')
    },
  },
}
</script>

<style lang="scss" scoped>
.icon_check {
  color: var(--black-color);
  font-size: 20px;
}
.title_menu {
	font-size: 22px;
	font-weight: 700;
	color: #636363;
}
</style>
