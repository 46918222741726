<template>
  <div>
    <b-row>
      <b-col
        v-for="(item, index) in listDisheByDiet.dishes"
        :key="index"
        cols="6"
        class="d-flex mb-1"
      >
        <div
          v-b-tooltip.hover.top="item.name"
          class="item_dishe2 mr-2"
        >
          {{ item.name }}
        </div>
        <b-badge
          variant="primary"
        >
          {{ item.items.length }}
        </b-badge>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
	BRow,
	BCol,
	BBadge,
	VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
		BRow,
		BCol,
		BBadge,
	},
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    listDisheByDiet: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style lang="css" scoped>
  .item_dishe2 {
    width: 140px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
		font-weight: 700;
  }
</style>
