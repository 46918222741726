<template>
  <div>
    <b-row>
      <b-col
        lg="12"
        md="12"
        sm="12"
        class="mb-1"
      >
        <date-picker
          v-model="rangeDates"
          is-range
          :attributes="attrs"
          color="blue"
          mode="date"
          :model-config="modelConfig"
          :columns="$screens({ default: 1, lg: 2 })"
          :available-dates="{ start: null, end: new Date() }"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <b-row>
              <b-col
                lg="6"
                md="6"
                sm="12"
              >
                <div role="group">
                  <label
                    class="title"
                  >Fecha inicial*</label>
                  <b-form-input
                    :value="inputValue.start"
                    v-on="inputEvents.start"
                  />
                </div>
              </b-col>
              <b-col
                lg="6"
                md="6"
                sm="12"
              >
                <div role="group">
                  <label
                    class="title"
                  >Fecha final*</label>
                  <b-form-input
                    :value="inputValue.end"
                    v-on="inputEvents.end"
                  />
                </div>
              </b-col>
            </b-row>
          </template>
        </date-picker>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        class="text-center mt-1 mb-1"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          :disabled="isBusy || $v.$invalid"
          @click="save"
        >
          <b-spinner
            v-if="isBusy"
            small
          />
          <v-icon
            v-else
            name="save"
          />
          Generar
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BSpinner,
  BButton,
  BFormInput,
} from 'bootstrap-vue'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import Ripple from 'vue-ripple-directive'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { API_ROUTES } from '../config/routes/api'

export default {
  inject: ['orderRepository'],
  components: {
    BRow,
    BCol,
    BSpinner,
    BButton,
    BFormInput,
    DatePicker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isBusy: false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      attrs: [
        {
          highlight: 'green',
          dates: new Date(),
        },
      ],
      rangeDates: {
        start: '',
        end: '',
      },
    }
  },
  computed: {
    ...mapGetters('session', ['getLoggedUser']),
  },
  validations: {
    rangeDates: {
      start: {
        required,
      },
      end: {
        required,
      },
    },
  },
  methods: {
    async save() {
      const me = this
      me.isBusy = true
      const a = document.createElement('a')
      const link = `${API_ROUTES.report.get}?fecha_inicial=${me.rangeDates.start}&fecha_final=${me.rangeDates.end}&customer_id=${me.getLoggedUser.customer_id}`

      a.href = link
      a.target = '_blank'
      a.click()
      me.isBusy = false
      me.$bvModal.hide('modal-report-order')
    },
  },
}
</script>

<style>

</style>
