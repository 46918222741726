<template>
  <div>
    <b-row class="">
      <b-col md="4">
        <b-card
          no-body
          class="card__"
        >
          <div class="pl-1 pb-0 d-flex justify-content-between align-items-center">
            Desayunos
            <b-badge
              variant="info"
              class="badge_statistc"
            >
              {{ menuDiets.breakfast.quantity }}
            </b-badge>
          </div>
          <div v-if="isBusy">
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-50" />
              <strong>Cargando...</strong>
            </div>
          </div>
          <div
            v-else
            class="pr-1"
          >
            <b-row>
              <b-col
                v-for="(item, index) in menuDiets.breakfast.items"
                :key="index"
                cols="6"
                class="d-flex mb-1 pl-3"
              >
                <div
                  v-b-tooltip.hover.top="item.tipo_diet"
                  class="item_dishe mr-2"
                >
                  {{ item.tipo_diet }}
                </div>
                <b-badge
                  variant="primary"
                  class="badge_total"
                  @click.prevent="listDishe(item)"
                >
                  {{ item.menuDiet.length }}
                </b-badge>
              </b-col>
            </b-row>
            <b-row class="pb-0 pl-1">
              <b-col
                cols="12"
                class="d-flex justify-content-between align-items-center pr-0 pb-0"
              >
                <div
                  class="mr-2"
                >
                  N° de meriendas
                </div>
                <b-badge
                  variant="danger"
                  class="badge_statistc"
                >
                  {{ menuDiets.breakfast.snacks.length }}
                </b-badge>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card
          no-body
          class="card__"
        >
          <div class="pl-1 pb-0 d-flex justify-content-between align-items-center">
            Almuerzos
            <b-badge
              variant="success"
              class="badge_statistc"
            >
              {{ menuDiets.luches.quantity }}
            </b-badge>
          </div>
          <div v-if="isBusy">
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-50" />
              <strong>Cargando...</strong>
            </div>
          </div>
          <div
            v-else
            class="pr-1"
          >
            <b-row>
              <b-col
                v-for="(item, index) in menuDiets.luches.items"
                :key="index"
                cols="6"
                class="d-flex mb-1 pl-3"
              >
                <div
                  v-b-tooltip.hover.top="item.tipo_diet"
                  class="item_dishe mr-2"
                >
                  {{ item.tipo_diet }}
                </div>
                <b-badge
                  variant="primary"
                  class="badge_total"
                  @click.prevent="listDishe(item)"
                >
                  {{ item.menuDiet.length }}
                </b-badge>
              </b-col>
            </b-row>
            <b-row class="pb-0 pl-1">
              <b-col
                cols="12"
                class="d-flex justify-content-between align-items-center pr-0 pb-0"
              >
                <div
                  class="mr-2"
                >
                  N° de meriendas
                </div>
                <b-badge
                  variant="danger"
                  class="badge_statistc"
                >
                  {{ menuDiets.luches.snacks.length }}
                </b-badge>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card
          no-body
          class="card__"
        >
          <div class="pl-1 pb-0 d-flex justify-content-between align-items-center">
            Cenas
            <b-badge
              variant="warning"
              class="badge_statistc"
            >
              {{ menuDiets.dinners.quantity }}
            </b-badge>
          </div>
          <div v-if="isBusy">
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-50" />
              <strong>Cargando...</strong>
            </div>
          </div>
          <div
            v-else
            class="pr-1"
          >
            <b-row>
              <b-col
                v-for="(item, index) in menuDiets.dinners.items"
                :key="index"
                cols="6"
                class="d-flex mb-1 pl-3"
              >
                <div
                  v-b-tooltip.hover.top="item.tipo_diet"
                  class="item_dishe mr-2"
                >
                  {{ item.tipo_diet }}
                </div>
                <b-badge
                  variant="primary"
                  class="badge_total"
                  @click.prevent="listDishe(item)"
                >
                  {{ item.menuDiet.length }}
                </b-badge>
              </b-col>
            </b-row>
            <b-row class="pb-0 pl-1">
              <b-col
                cols="12"
                class="d-flex justify-content-between align-items-center pr-0 pb-0"
              >
                <div
                  class="mr-2"
                >
                  N° de meriendas
                </div>
                <b-badge
                  variant="danger"
                  class="badge_statistc"
                >
                  {{ menuDiets.dinners.snacks.length }}
                </b-badge>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
	BSpinner,
	BBadge,
	VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
		BRow,
		BCol,
		BCard,
		BSpinner,
		BBadge,
	},
	directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    menuDiets: {
      type: Object,
      default: null,
    },
		isBusy: {
			type: Boolean,
			default: null,
		},
  },
  data() {
    return {}
  },
	methods: {
    listDishe(item) {
      this.$emit('listDishe', item)
    },
  },
}
</script>

<style lang="scss" scoped>
  .card__ {
    box-shadow: 0 5px 11px 0 rgba(145, 144, 144, 0.3), 0 4px 15px 0 rgba(133, 132, 132, 0.15);
  }

	.div__ {
		height: 242px;
	}

	.box__ {
		border: 1.5px solid #7367F0;
		padding: 20px;
		color: #7367F0;
		font-weight: bold;
	}

	.item_dishe {
    width: 100px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
		font-weight: 700;
  }

  .badge_total {
    cursor: pointer;
  }

  .badge_statistc {
    padding: 10px;
    font-size: 18px;
    border-radius: 0px;
  }

  // .badge_statistc:first-child {
  //   border-bottom-right-radius: 0px;
  //   border-bottom-left-radius: 0px;
  // }

  // .badge_statistc:last-child {
  //   border-top-right-radius: 0px;
  //   border-top-left-radius: 0px;
  // }
</style>
